.well,
.form-control,
.input-group-addon {
  border-radius: 0;
}

.well {
  padding-left: 15px;
  padding-right: 15px;
}

/* stylelint-disable  selector-class-pattern */
.form_metadata {
  display: none;
}
/* stylelint-enable  selector-class-pattern */

.form-error {
  margin-bottom: 0;
  padding-left: 30px;
  color: #c00;
}

form label.control-label {
  padding-right: 23px;
}

form label.required::after {
  position: absolute;
  content: '\00a0*';
  color: #f00;
}

form label.no-after::after {
  content: none;
}

form div.form-section {
  margin: 40px 0 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  background-color: #ddd;
}

form div.form-section:first-child {
  margin-top: 0;
}

/* Checkbox */
form label.checkbox {
  padding-left: 20px;
}

form label.checkbox,
form label.checkbox input {
  cursor: pointer;
}

.table-boolean-yes {
  color: #00a000;
}

.table-boolean-no {
  color: #c00000;
}

/* Entity Link */
.tp-table-entity-link i {
  padding-left: 5px;
  font-size: 80%;
  cursor: pointer;
  vertical-align: top;
  opacity: 0.5;
}

.tp-table-entity-link i:hover {
  opacity: 1;
}

/* Entity Popup */
.tp-table-entity-popup .input-group-addon {
  cursor: pointer;
}

.tp-table-popup td:first-child {
  width: 20px;
  text-align: center;
}

/* Vich Uploader Bundle */
/* stylelint-disable  selector-class-pattern */
.tp_table_vich img {
  max-width: 100%;
  max-height: 300px;
}
/* stylelint-enable  selector-class-pattern */

/* Tracking */
.tp-table-tracking-section {
  background-color: #eee;
  border-color: #ddd;
}

.tp-table-tracking-icon {
  padding: 0 15px 0 5px;
}

.tp-table-tracking-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: normal;
}

.tp-table-tracking-info {
  flex-grow: 1;
}

.tp-table-tracking-info p {
  margin: 0;
}

.tp-table-tracking-more {
  cursor: pointer;
}

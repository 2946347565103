@import './left-panel.scss';
@import './program-configuration.scss';
@import './notebook.scss';
@import './maps.scss';

@page {
  size: a4;
}

.sheet {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  opacity: 0;
}

/* Fix Safari, overwrite paper.css - height minus 2mm */
@media print {
  .sheet {
    height: 294mm !important;
  }
}

.sheet sup {
  top: -0.4em;
}

.program-print-pagination {
  position: absolute;
  bottom: 15px;
  width: 100%;
  text-align: center;
  margin-left: -10mm;
}

.program-print-pagination-logo {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
}

.program-print-background-opacity {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.5;
}

.program-print-back,
.program-print-print {
  position: fixed;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
  color: #555;
  cursor: pointer;
  z-index: 100;
  background-color: #fff;
  border-radius: 35px;
}

.program-print-back {
  right: 0;
  padding: 10px;
  font-size: 25px;
}

.program-print-print {
  right: 55px;
  height: 45px;
  width: 45px;
}

.program-print-print-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 25px;
}

.program-print-print-loading {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 10px;
  border-radius: 35px;
  background-color: #fff;
  white-space: nowrap;
  cursor: default;
  transition: width 1s cubic-bezier(0.39, 0.05, 1, 0.44),
    padding 1s cubic-bezier(0.39, 0.05, 1, 0.44),
    margin 1s cubic-bezier(0.39, 0.05, 1, 0.44);
  width: 140px;
  margin-right: 0;
  overflow: hidden;
}

.program-print-print-loading-hidden {
  width: 0;
  padding: 0;
  margin-right: 10px;
}

.program-print-print-loading span + span {
  margin-left: 6px;
}

.program-print-back:hover,
.program-print-back:focus,
.program-print-print:hover,
.program-print-print:focus {
  text-decoration: none;
  color: black;
}

@media print {
  .program-print-back,
  .program-print-print {
    display: none;
  }
}

.program-print-illustration {
  height: 140mm;
}

.program-print-small-illustrations {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6mm;
}

.program-print-illustration img,
.program-print-small-illustrations img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.program-print-logo {
  height: 20mm;
  margin: 5mm auto;
}

.program-print-name {
  text-align: center;
  font-size: xx-large;
  font-weight: bold;
}

.program-print-quote {
  font-style: italic;
  text-align: center;
}

.program-print-date {
  margin: 5mm;
  text-align: center;
  font-size: large;
}

.program-print-participants {
  text-align: center;
  font-size: 24px;
}

.program-print-contacts {
  position: absolute;
  bottom: 10.01mm; /* Issue on Chrome when splitting sheets if 10mm (thousand of sheets generated) */
  left: 0;
  width: 100%;
  text-align: center;
}

.program-print-contacts p {
  color: #bbb !important;
  line-height: 80%;
}

.program-print-contacts-small {
  font-size: 10px;
}

.program-print-day-day {
  text-transform: uppercase;
}

.program-print-day-date {
  margin-left: 5mm;
  font-size: 15px;
}

.program-print-day-name,
.program-print-accomodation-city {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 120mm;
  text-transform: uppercase;
  text-align: right;
}

.program-print-content {
  margin-left: 8mm;
  text-align: justify;
  font-size: 14px;
}

.program-print-activiy-stars,
.program-print-accomodation-stars {
  display: inline-block;
  vertical-align: top;
  font-size: 70%;
}

.program-print-activity-optional {
  font-style: italic;
}

.program-print-activity-optional::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50px;
  height: 100%;
  border-left: 1px solid #aaa;
}

h3 > .program-print-activity-optional {
  border-bottom: 1px solid #aaa;
}

h3 > .program-print-activity-optional::before {
  border-left: none;
}

.program-print-images {
  padding: 10px 0;
  text-align: center;
}

.program-print-images img {
  vertical-align: top;
  max-height: 50mm;
  max-width: 182mm;
}

.program-print-images img + img {
  margin-left: 2mm;
}

.program-print-images img:first-child {
  margin-left: 0;
}

.program-print-images img:last-child {
  margin-right: 0;
}

.program-print-end-of-service {
  margin-top: 10mm;
  text-align: center;
  line-height: 80%;
  font-size: 15px;
  font-weight: bold;
}

.program-print-blocs,
.program-print-total-amount {
  margin-top: 20px;
  margin-bottom: 15px;
}

.program-print-bloc-payer {
  font-style: italic;
}

.program-print-price-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.program-print-price-blue {
  font-weight: bold;
  color: #0e2e70 !important;
}

.program-print-price-big {
  font-size: 25px;
  font-weight: bold;
  color: #0e2e70 !important;
}

.program-print-total-amount-per-person-msg {
  line-height: 39px;
  font-weight: bold;
}

.program-print-price-and-conditions-separator {
  margin-top: 5mm;
  margin-bottom: 5mm;
  border-bottom: 1px solid #aaa;
}

.program-print-cotation-name {
  line-height: 20px;
}

.program-print-country-details {
  margin-left: 20px;
}

.program-print-agent {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

/* Added :first-child just so that css selector specificities priorities are respected...
(really need to split this file) */
.program-print-agent-photo img:first-child {
  max-width: 100%;
  height: auto;
}

.program-print-agent-name {
  margin-bottom: 15px;
  font-weight: bold;
}

.program-print-loading {
  width: 200px;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: -100px;
  background-size: 200px;
}

.program-print-voucher {
  & .program-print-logo {
    height: 30mm;
  }
}

.program-print-voucher-accomodation-date {
  white-space: nowrap;
}

.program-print-voucher-accomodations-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  font-size: 13px;
}

.program-print-voucher-accomodation-name {
  font-weight: bold;
}

#app-print-program {
  font-family: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: url('#') no-repeat center fixed;
  background-size: cover;
  background-color: #ccc;

  & h1.program-print-title {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
    border-bottom: solid 1px;
    margin-bottom: 20px;
  }

  & h2.program-print-title {
    position: relative;
    font-size: 20px;
    border-bottom: solid 1px;
    margin-bottom: 21px;
  }

  & h1.program-print-title,
  & h2.program-print-title,
  & h1.program-print-title span,
  & h2.program-print-title span {
    color: #0e2e70 !important;
  }

  & h3.program-print-title {
    font-size: 15px;
    font-weight: bold;
  }

  & .program-print-voucher {
    & h1.program-print-title {
      margin-bottom: 5mm;
      text-align: center;
      border-bottom: none;
    }

    & h3.program-print-title {
      text-transform: uppercase;
    }
  }

  /* So that the optional vertical line is continuous (no border along margins) */
  & p {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

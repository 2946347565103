.tp-table-list-buttons-title {
  width: 100px;
}

.tp-table-list-buttons {
  width: 100px;
  float: right;
}

.app-no-record {
  margin: 20px 10px;
  padding: 20px 30px;
  border-radius: 5px;
  color: #286090;
  border: 1px solid #286090;
}

:root {
  --border: 1px solid #ccc;
}

.program-header-program-template {
  margin-top: 20px;
  padding: 15px 0;
  border-top: var(--border);
  border-bottom: var(--border);
  text-align: center;
  font-size: large;
  cursor: pointer;
}

.program-header {
  display: grid;
  grid-template-columns: 17% auto 17%;
  grid-template-rows: auto;
  margin: 20px auto;
  border-top: var(--border);
  border-bottom: var(--border);
}

.program-header-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 110px;
  overflow: hidden;
  font-size: x-large;
  cursor: pointer;
}

.program-header-tile-active,
.program-header-list-element-active {
  background-color: #e6e6e6;
}

.program-header-tile,
.program-header-tile:hover,
.program-header-tile:focus,
.program-header-tile:visited,
.program-header-tile:active,
.program-header-print {
  color: black;
  text-decoration: none;
}

.program-header-tile:hover,
.program-header-tile:focus {
  background-color: #eee;
}

.program-header-list {
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-rows: auto;
  border-left: var(--border);
  border-right: var(--border);
}

.program-header-list:first-child {
  border-bottom: var(--border);
}

.program-header-list-title {
  min-height: 55px;
  padding: 16px 35px 0 5px;
  text-align: right;
  overflow: hidden;
}

.program-header-list-elements {
  margin: 10px 10px 0;
  display: grid;
  grid-template-columns: auto 155px;
  grid-template-rows: auto;
}

.program-header-list-elements ul {
  position: relative;
  padding-left: 0;
}

.program-header-list-elements > :last-child {
  display: flex;
  justify-content: flex-end;
}

.program-header-list-elements li {
  position: relative;
  margin-right: 5px;
}

.program-header-list-elements li:last-child {
  margin-right: 0;
}

.program-header-list-elements i.glyphicon-ok {
  position: absolute;
  top: -7px;
  right: -5px;
  color: #5cb85c;
}

.program-header-list-elements i.glyphicon-warning-sign {
  position: absolute;
  top: 23px;
  right: -5px;
  color: #ff9f17;
}

li.program-day-add-label {
  margin-right: 0;
  padding-left: 48px;
}

.program-day-add-arrow {
  position: absolute;
  top: -21px;
  left: -30px;
  width: 45px;
  height: 25px;
  background-image: url('../images/arrow.png');
  background-repeat: no-repeat;
  background-size: 45px;
}

#program-header-draggable {
  position: absolute;
  top: 0;
  left: 0;
}

#program-header-days li,
#program-header-draggable {
  width: 42px;
}

#program-header-cotation-add {
  margin-right: 0;
}
